<template>
  <div class="row justify--center align-center">
    <va-card class="flex xs12 sm12 md12 lg12 xl12">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #filters>
            <div class="flex">
              <va-select
                searchable
                v-model="atendente"
                label="Atendente"
                :options="atendentes"
              />
            </div>
            <div class="flex">
              <va-select
                searchable
                v-model="status"
                label="Status"
                :options="allStatus"
              />
              <va-badge
                :text="table.items.length || '0'"
                color="danger"
                class="mr-2"
                style="margin-left: -3%;"
              />
            </div>
            <div class="flex">
              <va-switch
                v-model="apenaspendentes"
                true-inner-label="Apenas Pendentes"
                false-inner-label="Apenas Pendentes"
              />
            </div>
          </template>
          <template #cell(acoes)="{ source: acoes }">
            <va-button @click="$router.push({name: 'painel-chamados-detalhes', params: { cha_id: acoes } })" icon="visibility" color="#692BEB" gradient class="mr-1" />
            <va-button @click="() => redirectToTomTicket(this, acoes)" icon="outbound" color="#9e4657" gradient />
          </template>
          <template #cell(cha_protocolo)="{ source: cha_protocolo }">
            #{{ cha_protocolo }}
          </template>
          <template #cell(cst_nome)="{ source: cst_nome }">
            <va-chip :color="getColor(cst_nome)">{{ cst_nome }}</va-chip>
          </template>
          <template #cell(cha_data_criacao)="{ source: cha_data_criacao }">
            {{ cha_data_criacao ? formatDate(cha_data_criacao, 'YYYY-MM-DD HH:ii:ss','DD/MM') : '' }}
          </template>
          <template #cell(prazo_dia)="{ source: prazo_dia }">
            {{ prazo_dia ? formatDate(prazo_dia, 'YYYY-MM-DD','DD/MM') : '' }}
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {
  refresh,
  setAtendentes,
  getColor,
  redirectToTomTicket, setStatus
} from "./ChamadosFunctions";
import {formatDate} from "@/functions/Formatters"
import ChamadosTable from "./ChamadosTable";

export default {
  name: "Chamados",
  async mounted() {
    await setAtendentes(this)
    await setStatus(this)
  },
  data() {
    return {
      table: ChamadosTable,
      atendente: 'Todos',
      apenaspendentes: true,
      atendentes: ['Todos'],
      status: 'Todos',
      allStatus: ['Todos']
    }
  },
  methods:{
    refresh,
    getColor,
    formatDate,
    redirectToTomTicket
  },
  watch: {
    apenaspendentes() {
      this.table.refresh = true;
    },
    atendente() {
      this.table.refresh = true;
    },
    status() {
      this.table.refresh = true;
    }
  }
}
</script>


<style>
.table-chamados .va-data-table__table-td {
  max-width: 150px;
  white-space: normal;
}
.va-switch .va-switch__track, .va-switch__checker {
  border-radius: var(--va-square-border-radius);
}
.va-switch__inner {
  height: var(--va-button-size);
}
</style>