import moment from 'moment';

/**
 * https://momentjs.com/
 */
export function formatDate(date:string, inputFormat:string, outputFormat:string): string
{
    return moment(date, inputFormat).format(outputFormat);
}


export function formatMoney(value:string|number): string
{
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
}

export function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
