<template>
  <div>
    <div style="text-align: center; width: 100%">
      <va-button class="my-2" color="success" gradient @click="salvarSites(this)">
        Salvar Sites
      </va-button>
    </div>
    <va-data-table
      class="table-clientes-sites"
      :no-data-html="'Sem sites cadastrados'"
      :items="items"
      :columns="columns"
      :animated="true"
      :hoverable="true"
      :striped="true"
    >
      <template #headerAppend>
        <tr class="table-example--slot mb-2">
          <th
            v-for="key in Object.keys(novo)"
            :key="key"
            colspan="1"
          >
            <va-input
              v-model="novo[key]"
              outline
            />
          </th>
          <th colspan="1">
            <va-button
              @click="addNewItem()"
            >
              +
            </va-button>
          </th>
        </tr>
      </template>
      <template #cell(cwe_url)="{ source: cwe_url }">
        {{ cwe_url }}
      </template>
      <template #cell(numero)="{ source: cwe_obs }">
        {{ cwe_obs }}
      </template>
      <template #cell(cwe_actions)="{ rowIndex }">
        <va-button flat icon="edit" @click="openModalToEditItemById(rowIndex)" />
        <va-modal
          :model-value="!!editedItem"
          message="Editar site"
          @ok="editItem"
          @cancel="resetEditedItem"
        >
          <slot>
            <va-input
              v-for="key in Object.keys(editedItem)"
              :key="key"
              :class="[key === 'cwe_id'?'hidden':'', key === 'cwe_emp_id'?'hidden':'',key === 'cwe_data_criacao'?'hidden':'', key === 'cwe_data_update'?'hidden':'', key === 'cwe_actions'?'hidden':'', 'my-3']"
              :label="key"
              v-model="editedItem[key]"
            />
          </slot>
        </va-modal>
        <va-button flat icon="delete" color="danger" gradient @click="deleteItemById(rowIndex)" />
      </template>

    </va-data-table>
  </div>
</template>

<script>
import EditarSiteTable from './EditarSitesTable';
import {getSiteList, salvarSites} from "./EditarSitesFunctions";

const defaultItem = {
  cwe_url: '',
  cwe_obs: ''
}

export default {
  name: "EditarSite",
  props:['idCliente'],
  async mounted() {
    await getSiteList(this);
  },
  data(){
    return{
      columns: EditarSiteTable.columns,
      items: [],
      novo: defaultItem,
      cwe_url: false,
      editedItemId: null,
      editedItem: null,
      id: this.idCliente
    }
  },
  methods:{
    salvarSites,
    deleteItemById(id) {
      this.items = [
        ...this.items.slice(0, id),
        ...this.items.slice(id + 1),
      ]
    },
    addNewItem () {
      this.items = [...this.items, { ...this.novo }]
      this.resetCreatedItem()
    },
    resetCreatedItem () {
      this.novo = { ...defaultItem }
    },
    editItem () {
      this.items = [
        ...this.items.slice(0, this.editedItemId),
        { ...this.editedItem },
        ...this.items.slice(this.editedItemId + 1),
      ]
      this.resetEditedItem()
    },
    openModalToEditItemById (id) {
      this.editedItemId = id
      this.editedItem = { ...this.items[id] }
    },
    resetEditedItem () {
      this.editedItem = null
      this.editedItemId = null
    }
  }
}
</script>

<style>
</style>