export default {
    columns: [
        { key: 'cco_cargo', label: 'Cargo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_whatsapp', label: 'Whatsapp', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_fone', label: 'Telefone', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_email', label: 'Email', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_notificar_financeiro', label: 'Financeiro', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_notificar_chamados', label: 'Chamados', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_obs', label: 'Obs', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cco_actions', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    columnsSelecteds:[],
    columnsSelectedsKeys: {
        cco_cargo: true,
        cco_whatsapp: true,
        cco_email: true,
    },
    filtered: [],
    currentPage: 1,
    perPage: 10,
    filter:'',
}