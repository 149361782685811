export default {
    columns: [
        { key: 'ser_nome', label: 'Serviço', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cse_data_inicio', label: 'Data Início', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cse_data_fim', label: 'Data Fim', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cse_valor_liquido', label: 'Liquido', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cse_valor_bruto', label: 'Bruto', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cse_obs', label: 'Obs', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cwe_actions', label: 'Ações', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
    ],
    columnsSelecteds:[],
    columnsSelectedsKeys: {
        ser_nome: true,
        cse_data_inicio: true,
        cse_data_fim: true,
    },
    filtered: [],
    currentPage: 1,
    perPage: 10,
    filter:'',
    items: [],
}