import { FetchWs } from "@/functions/FetchWs";

export async function refresh(vue: any, loader = true) {
    let query = `?a=a`;
    if (vue.apenaspendentes) {
        query = query + `&apenas_pendente=true`
    }
    if (vue.atendente !== 'Todos') {
        query = query + `&atendente=${vue.atendente}`
    }
    const stringSituacao = vue.status.trim().replace('(', '').replace(')', '').replace(' ', '').replace(/[0-9]/g, '')
    if (vue.status) {
        if(stringSituacao === 'Concluído'){
            query = query + `&situacao=1`
        } else if(stringSituacao === 'Pendente') {
            query = query + `&situacao=2`
        } else if (stringSituacao === 'Pausado') {
            query = query + `&situacao=3`
        } else if (stringSituacao === 'Revisão') {
            query = query + `&situacao=4`
        }
    }
    const response = await FetchWs('chamado/get-all' + query, 'GET', {}, {}, loader);
    const data = response?.data;

    if(data && Array.isArray(data)){
        vue.table.items = assertChamados(data);
    } else {
        vue.table.items = []
    }
    await setStatus(vue)
}

function assertChamados(objs: any): any[]
{
    objs.forEach((chamado:any,key:number) => {
        objs[key].acoes = chamado.cha_id
        if (chamado.cha_cst_id === 1) {objs[key].cst_nome = 'Entregue';}
        else if (chamado.cha_cst_id === 3) {objs[key].cst_nome = 'Pausado';}
        else if (
            !chamado.cli_whatsapp ||
            !chamado.cli_email ||
            !chamado.cli_nome ||
            !chamado.cha_atendente ||
            !chamado.cha_ccp_id ||
            !chamado.cli_nome_empresa
        ) {objs[key].cst_nome = 'Triagem';}
        else if (chamado.cha_cst_id === 4) {objs[key].cst_nome = 'Revisão';}
        else if (chamado.atraso === 1) {objs[key].cst_nome = 'Atrasado';}
        else if (chamado.quase_atraso === 1) {objs[key].cst_nome = 'Atrasando';}
        else {objs[key].cst_nome = 'Pendente';}
    })
    return objs;
}

export async function setAtendentes(vue: any) {
    const response = await FetchWs('user/get-all-atendentes');
    const data = response?.data;

    if(data && Array.isArray(data)){
        vue.atendentes = ['Todos'].concat(data.map((atendente)=> {
            return atendente.usu_name
        }))
    }

}


export async function setStatus(vue: any) {
    vue.allStatus = []
    const response = await FetchWs('chamado/get-all-status');
    const data = response?.data;

    if(data && Array.isArray(data)){
        vue.allStatus = ['Todos'].concat(data.map((stats)=> {
            // @ts-ignore
            return stats.cst_nome  + ' (' + vue.table.items.filter(number => number.cha_cst_id === stats.cst_id).length + ')'
        }))
    }

}

export function redirectToTomTicket(vue: any, external_id: string){
    const chamado = {...vue.table.items.find((chamado: any)=> chamado.cha_id === external_id)}
    const url = 'https://painel.tomticket.com/painel.html#!/chamado/vincular/' + chamado.cha_id_externo
    window?.open(url, '_blank')?.focus();
}

export function getColor(cst_nome: string){
    switch (cst_nome){
        case 'Entregue':
            return 'success'
        case 'Pendente':
            return 'primary'
        case 'Triagem':
            return 'rgb(255, 251, 10)'
        case 'Atrasando':
            return 'rgb(255, 169, 58)'
        case 'Atrasado':
            return 'danger'
        case 'Revisão':
            return 'rgb(146,220,231)'
    }
    return '#ccc'
}
