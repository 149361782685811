import { FetchWs } from "@/functions/FetchWs";

export function redirectToTomTicket(vue: any, external_id: string){
    const chamado = {...vue.table.items.find((chamado: any)=> chamado.cha_id === external_id)}
    const url = 'https://painel.tomticket.com/painel.html#!/chamado/vincular/' + chamado.cha_id_externo
    window?.open(url, '_blank')?.focus();

}
export async function refresh(vue: any, loader = true) {
    const dataInicio = padStr(vue.dataInicio.getFullYear()) + '-' + padStr(vue.dataInicio.getMonth() + 1) + '-' + padStr(vue.dataInicio.getDate());
    const dataFim = padStr(vue.dataFim.getFullYear()) + '-' + padStr(vue.dataFim.getMonth() + 1) + '-' + padStr(vue.dataFim.getDate());
    let query = `?realatorio_pontuacao=true&encerramento_inicio=${dataInicio} 00:00:00&encerramento_fim=${dataFim} 23:59:00`;
    if (vue.atendente !== 'Todos') {
        query = query + `&atendente=${vue.atendente}`
    }
    const response = await FetchWs('chamado/get-all' + query, 'GET', {}, {}, loader);
    const data = response?.data;

    if(data && Array.isArray(data)){
        vue.table.items = assertChamados(data);
    } else {
        vue.table.items = [];
    }
    calculaPontosTotais(vue);
}

function padStr(i: number) {
    return (i < 10) ? "0" + i : "" + i;
}
function calculaPontosTotais(vue: any) {
    vue.pontuacaoAtendentes = {};
    vue.table.items.forEach((chamado: any) => {
        if (!vue.pontuacaoAtendentes[chamado.cha_atendente]) {vue.pontuacaoAtendentes[chamado.cha_atendente] = 0;}
        vue.pontuacaoAtendentes[chamado.cha_atendente] += chamado.pontos_totais;
    });

}

export async function setAtendentes(vue: any) {

    const response = await FetchWs('user/get-all-atendentes');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.atendentes = ['Todos'].concat(data.map((atendente)=> {
            return atendente.usu_name
        }))
    }

}

export function getColor(cst_nome: string){
    switch (cst_nome){
        case 'Entregue':
            return 'success'
        case 'Atraso':
        case 'Retrabalho':
        case 'Atraso/Retrabalho':
            return 'danger'
    }
    return '#ccc'
}

function assertChamados(objs: any): any[]
{
    objs.forEach((chamado:any,key:number) => {
        objs[key].acoes = chamado.cha_id
        if (chamado.atraso === 1 && chamado.cha_retrabalho === 1) {objs[key].cst_nome = 'Atraso/Retrabalho';}
        else if (chamado.atraso === 1) {objs[key].cst_nome = 'Atraso';}
        else if (chamado.cha_retrabalho === 1) {objs[key].cst_nome = 'Retrabalho';}
        else {objs[key].cst_nome = 'Entregue';}
    })
    return objs;
}