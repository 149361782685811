export default {
    data:{
      readonly: false
    },
    struct: [
        [
            {
                col: 0,
                name: "cha_id",
                type: "hidden",
                value: "",
                label: false,
                customClass: 'hidden'
            },
            {
                col: 2,
                name: "cha_protocolo",
                type: "text",
                value: "",
                label: "Protocolo",
                readonly: true
            },
            {
                col: 2,
                name: "ccp_prioridade",
                type: "text",
                value: "",
                label: "Prioridade",
                readonly: true,
            },
            {
                col: 2,
                name: "cst_nome",
                type: "text",
                value: "",
                label: "Status",
                readonly: true,
            },
            {
                col: 2,
                name: "atraso",
                type: "text",
                value: "",
                label: "Atraso",
                readonly: true,
            },
            {
                col: 1,
                name: "cha_nps_nota",
                type: "text",
                value: "",
                label: "NPS",
                readonly: true,
            },
            {
                col: 3,
                name: "cha_titulo",
                type: "text",
                value: "",
                label: "Titulo",
                readonly: true,
            }
        ],
        [
            {
                col: 12,
                name: "cha_mensagem",
                type: "textarea",
                value: "",
                label: "Primeira Mensagem",
                minRows: 10,
                readonly: true,
            }
        ],
        [
            {
                col: 3,
                name: "cha_data_criacao",
                type: "text",
                value: "",
                label: "Criação",
                readonly: true,
            },
            {
                col: 3,
                name: "prazo_dia",
                type: "text",
                value: "",
                label: "Prazo",
                readonly: true,
            },
            {
                col: 3,
                name: "cha_data_encerramento",
                type: "text",
                value: "",
                label: "Encerramento",
                readonly: true,
            },
            {
                col: 3,
                name: "ccp_pontos",
                type: "text",
                value: "",
                label: "Pontos da Complexidade",
                readonly: true,
            }
        ],
        [
            {
                col: 2,
                name: "cha_retrabalho",
                type: "select",
                value: "N/A",
                options:['NÃO','SIM'],
                label: "Retrabalho",
                required: true,
            },
            {
                col: 5,
                name: "ccp_nome",
                type: "select",
                value: "N/A",
                options: [],
                label: "Complexidade",
                searchable: true,
                required: true,
            },
            {
                col: 3,
                name: "cha_atendente",
                type: "select",
                value: "",
                options: [],
                label: "Atendente",
                searchable: true,
                required: true,
            },
            {
                col: 2,
                name: "cha_pontos",
                type: "number",
                value: "",
                label: "Pontos Customizado",
                required: false,
            }
        ]
    ]
}