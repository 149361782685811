import {FetchWs} from "@/functions/FetchWs";
import {formatDate} from "@/functions/Formatters";
import {setOptionsForm,setDadosForm} from "@/components/monta-form/functions"

export async function sendFormCliente(vue: any, data: any){
    const body = new FormData();
    body.append("nome", data.cli_nome);
    body.append("email", data.cli_email);
    body.append("nome_empresa", data.cem_nome_fantasia);
    body.append("whatsapp", data.cli_whatsapp);
    body.append("cli_cem_id", getNfidFromCemNomeFantasia(vue,data.cem_nome_fantasia));
    await FetchWs('cliente_tt/update/' + data.cha_cli_id, 'POST', body);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}
export async function sendFormChamado(vue: any, data: any){
    const body = new FormData();
    body.append("cha_retrabalho", data.cha_retrabalho === 'SIM' ? '1' : '0');
    body.append("cha_ccp_id", getCcpidFromCcpnome(vue,data.ccp_nome));
    body.append("cha_atendente", data.cha_atendente);
    body.append("cha_pontos", data.cha_pontos ? data.cha_pontos : '0');
    await FetchWs('chamado/update/' + data.cha_id, 'POST', body);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}

export async function loadChamadoAndCliente(vue: any){
    const cha_id = vue.$route.params.cha_id
    const response = await FetchWs('chamado/get-all?cha_id=' + cha_id);
    const data = response?.data;

    const revisao = data[0].cha_cst_id
    parseInt(revisao) == 4 ? vue.revisao = false : vue.revisao = true

    if(data && Array.isArray(data) && data.length > 0){
        vue.chamado = data[0];
    }
}

export async function loadAtendentes(vue: any) {
    const response = await FetchWs('user/get-all-atendentes');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.atendentes = data.map((atendente) => atendente.usu_name)
    }
}

export async function loadCategorias(vue: any) {
    const response = await FetchWs('chamado/get-categories');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.categorias = data;
    }
}

export async function loadNomeFantasia(vue: any) {
    const response = await FetchWs('cliente/get-nome-fantasia');
    const data = response?.data;

    if(data){
        vue.nomeFantasia = data;
    }
}

export function loadForms(vue: any){
    setOptionsForm(vue.formChamado, 'cha_atendente', vue.atendentes)
    setOptionsForm(vue.formChamado, 'ccp_nome', vue.categorias.map((cat:any)=>cat.ccp_nome))
    setOptionsForm(vue.formCliente, 'cem_nome_fantasia', vue.nomeFantasia.map((nf:any)=>nf.cem_nome_fantasia))
    if(!vue.chamado.cli_whatsapp || !vue.chamado.cli_nome_empresa || !vue.chamado.cli_nome  || !vue.chamado.cli_email){
        vue.formChamado.data.readonly = true;
    }
    const forms = ['formChamado','formCliente'];
    forms.forEach((form) => {
        vue[form].struct.forEach((line: any, lineKey: any) => {
            line.forEach((field: any, fieldKey: any) => {
                const index = vue[form].struct[lineKey][fieldKey].name;
                const value = vue.chamado[index];
                setDadosForm(vue[form], index, assertValue(index,value))
            })
        })
    })
    vue.loaded = true
}

function assertValue(index: any, value: any) {
    switch (index){
        case 'atraso':
        case 'cha_retrabalho':
            return value ? 'SIM' : 'NÃO';
        case 'cha_nps_nota':
        case 'ccp_prioridade':
        case 'ccp_pontos':
            return value ? value : 'N/A';
        case 'cha_data_encerramento':
        case 'prazo_dia':
            return value ? formatDate(value, 'YYYY-MM-DD','LL') : 'N/A';
        case 'cha_data_criacao':
            return value ? formatDate(value, 'YYYY-MM-DD H:m:i','LLL') : 'N/A';
        default:
            return value;
    }
}

function getCcpidFromCcpnome(vue: any, ccp_nome: string){
    const cat = vue.categorias.find((cat: any) => cat.ccp_nome == ccp_nome);
    return cat.ccp_id
}

function getNfidFromCemNomeFantasia(vue: any, cem_nome_fantasia: string){
    const nomeFantasia = vue.nomeFantasia.find((nf: any) => nf.cem_nome_fantasia == cem_nome_fantasia);
    return nomeFantasia.cem_id
}

export async function updatePrazo(vue:any, data:any){
    const body = new FormData();
    body.append("cha_id", vue.chamado.cha_id);
    body.append("cha_protocolo", vue.chamado.cha_protocolo);
    body.append("prazo_dia", data.prazo_dia);
    body.append("logger_justificativa", data.logger_justificativa);
    await FetchWs('chamado/update-deadline/', 'POST', body);
    vue.showPrazoModal = false
}

export async function finalizarChamado(vue:any, data:any){
    const body = new FormData();
    body.append("cha_id", vue.chamado.cha_id);
    body.append("motivo_finalizacao", data.motivo_finalizacao);

    //formatar para tipo time
    const minutoTrabalho = data.tempo_trabalho.slice(-2)
    const horaTrabalho = data.tempo_trabalho.slice(0,2)
    const formatTempoTrabalho = horaTrabalho + ':' + minutoTrabalho + ':00'

    body.append("tempo_trabalho", formatTempoTrabalho);
    await FetchWs('chamado/finish-ticket', 'POST', body);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}

export async function excluirChamado(vue:any){
    await FetchWs('chamado/delete-ticket/' + vue.chamado.cha_id);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}

export async function chamadoExcluido(vue:any, id:string){
    const resp = await FetchWs('chamado/get-ticket/' + id);
    if (typeof resp === 'object'){
        vue.btnExcluir = !resp.data
    }
}

export async function alterarParaRevisao(vue:any){
    await FetchWs('chamado/change-to-review/' + vue.chamado.cha_id);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}

export async function alterarParaDevolver(vue:any){
    await FetchWs('chamado/change-to-return/' + vue.chamado.cha_id);
    await vue.$router.push({name: 'painel-chamados-listagem'})
}

export async function getQtdChamados(vue:any){
    const response = await FetchWs('chamado/get-qty-tickets/' + vue.chamado.cha_cli_id);
    const data = response?.data;

    const contagem = data[0][0].contagem
    let limitTickets = data[1][0].limitTickets
    let limitEmails = data[2][0].limitEmails

    limitTickets == null ? limitTickets = 0 : limitTickets
    limitEmails == null ? limitEmails = 0 : limitEmails

    if(data){
        vue.qtd_chamados = contagem;
        vue.limite_chamados = limitTickets;
        vue.limite_emails = limitEmails;
    }
}

export async function verifyCompany(vue:any){
    const response = await FetchWs('cliente/verify-company/' + vue.chamado.cha_cli_id);
    const data = response?.data;

    if(data){
        vue.verifyCompany = true
    }
}