// https://github.com/nosir/cleave.js/blob/master/doc/options.md

import {isString} from "@/functions/Validations";

export function phoneMask(value = '') {
    if (!isString(value)) {
        return false;
    }
    if (value.length > 10) {
        return {
            numericOnly: true,
            blocks: [0, 2, 0, 1, 4, 4],
            delimiters: ["(", ")", " ", " ", "-"]
        }
    } else {
        return {
            numericOnly: true,
            blocks: [0, 2, 0, 4, 5],
            delimiters: ["(", ")", " ", "-"]
        }
    }
}

export function timeMask(value = '') {
    if (!isString(value)) {
        return false;
    }
    return {
        numericOnly: true,
        blocks: [2,2],
        delimiters: [":"]
    }
}