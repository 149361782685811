<template>
  <monta-form v-if="formCarregado" :key="formCarregado" :form="form" @complete="(data)=>submitFormUpdate(data, this)"/>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm";
import {getCliente, carregaDadosParaFormUpdate, submitFormUpdate} from "./EditarDadosFunctions";

export default {
  name: "EditarDados",
  props:['idCliente'],
  components: {MontaForm},
  async mounted() {
    this.carregaDadosParaFormUpdate(this)
    await getCliente(this.id);
  },
  data() {
    return {
      form: {},
      formCarregado: 0,
      id: this.idCliente
    }
  },
  methods:{
    carregaDadosParaFormUpdate,
    submitFormUpdate
  },
}
</script>

<style scoped>

</style>