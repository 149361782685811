export default {
    struct: [
        [
            {
                col: 12,
                name: "prazo_dia",
                value: "",
                type: "date",
                label: "Alterar para:",
                required: true
            },
            {
                col: 12,
                name: "logger_justificativa",
                value: "",
                type: "textarea",
                label: "Justificativa",
                required: true
            }
        ]
    ]
}