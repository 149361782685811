export default {
    name: "table-pontos",
    columns: [
        { key: 'acoes', label: 'Ações', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'cha_protocolo', label: 'Protocolo', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'cst_nome', label: 'Situação', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'cli_nome', label: 'Cliente', sortable: false, alignHead: 'center',align: 'center', verticalAlign: 'middle', },
        { key: 'cha_atendente', label: 'Atendente', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cha_titulo', label: 'Titulo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cha_data_criacao', label: 'Criação', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'cha_data_encerramento', label: 'Finalização', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'prazo_dia', label: 'Prazo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'ccp_prioridade', label: 'Prioridade', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle', },
        { key: 'cli_nome_empresa', label: 'Nome Empresa', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'cli_email', label: 'Email', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'cha_status_externo', label: 'Status Externo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'cha_departamento', label: 'Departamento', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'cha_categoria', label: 'Categoria', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'ccp_prazo', label: 'Dias de Prazo', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'ccp_pontos', label: 'Pontos Base', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
        { key: 'pontos_totais', label: 'Pontos Totais', sortable: false, alignHead: 'center', align: 'center', verticalAlign: 'middle',},
    ],
    columnsSelectedsKeys: {
        acoes: true,
        cha_protocolo: true,
        cst_nome: true,
        cli_nome: true,
        cha_atendente: true,
        cha_titulo: true,
        cha_data_encerramento: true,
        pontos_totais: true,
    },
    items: []
}