import {FetchWs} from "@/functions/FetchWs";
import FormStruct from "./EditarDadosFormStruct";

export async function getCliente(id: number): Promise<any>
{
    const response = await FetchWs('cliente/get-client/' +id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

export async function carregaDadosParaFormUpdate(vue: any){
    const client = await getCliente(vue.id)
    const struct = FormStruct;

    struct[0][0].value = client[0]['cem_situacao']
    struct[0][1].value = client[0]['cem_agencia']
    struct[0][2].value = client[0]['cem_tipo_pessoa']
    struct[1][0].value = client[0]['cem_segmento'] === "null" ? '' : client[0]['cem_segmento']
    struct[1][1].value = client[0]['cem_consultor'] === "null" ? '' : client[0]['cem_consultor']
    struct[2][0].value = client[0]['cem_documento'] === "null" ? '' : client[0]['cem_documento']
    struct[2][1].value = client[0]['cem_contaazul_code'] === "null" ? '' : client[0]['cem_contaazul_code']
    struct[3][0].value = client[0]['cem_nome_fantasia'] === "null" ? '' : client[0]['cem_nome_fantasia']
    struct[3][1].value = client[0]['cem_razao_social'] === "null" ? '' : client[0]['cem_razao_social']
    struct[4][0].value = client[0]['cem_link_drive'] === "null" ? '' : client[0]['cem_link_drive']
    struct[5][0].value = client[0]['cem_endereco_logradouro'] === "null" ? '' : client[0]['cem_endereco_logradouro']
    struct[5][1].value = client[0]['cem_endereco_numero'] === "null" ? '' : client[0]['cem_endereco_numero']
    struct[6][1].value = client[0]['cem_endereco_complemento'] === "null" ? '' : client[0]['cem_endereco_complemento']
    struct[6][1].value = client[0]['cem_endereco_bairro'] === "null" ? '' : client[0]['cem_endereco_bairro']
    struct[7][0].value = client[0]['cem_endereco_cep'] === "null" ? '' : client[0]['cem_endereco_cep']
    struct[7][1].value = client[0]['cem_endereco_cidade'] === "null" ? '' : client[0]['cem_endereco_cidade']
    struct[7][2].value = client[0]['cem_endereco_estado'] === "null" ? '' : client[0]['cem_endereco_estado']
    struct[8][0].value = client[0]['cem_obs'] === "null" ? '' : client[0]['cem_obs']
    struct[9][0].value = client[0]['cem_data_criacao']
    struct[9][1].value = client[0]['cem_data_update']
    vue.form.struct = struct
    vue.formCarregado++
}

export async function submitFormUpdate(data: any, vue: any)
{
    const date = new Date();
    const current_date = date.getFullYear()+"-"+String(date.getMonth()+1).padStart(2, '0')+"-"+String(date.getDate()).padStart(2, '0')+ ' ' +String(date.getHours()).padStart(2, '0')+":"+String(date.getMinutes()).padStart(2, '0')+":"+date.getSeconds();

    const body = new FormData();
    body.append("agencia", data.cli_agencia);
    body.append("contaazul_code", data.cli_contaazul_code);
    body.append("consultor", data.cli_consultor);
    body.append("documento", data.cli_documento);
    body.append("bairro", data.cli_bairro);
    body.append("cep", data.cli_cep);
    body.append("cidade", data.cli_cidade);
    body.append("complemento", data.cli_complemento);
    body.append("data_update", data.usu_grp_id);
    body.append("estado", data.cli_estado);
    body.append("logradouro", data.cli_logradouro);
    body.append("numero", data.cli_numero);
    body.append("drive", data.cli_drive);
    body.append("nome_fantasia", data.cli_nome_fantasia);
    body.append("obs", data.cli_obs);
    body.append("razao_social", data.cli_razao_social);
    body.append("segmento", data.cli_segmento);
    body.append("situacao", data.cli_situacao);
    body.append("tipo_pessoa", data.cli_tipo_pessoa);
    body.append("data_update", current_date);
    const response = await FetchWs('cliente/update/' + vue.id, 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}