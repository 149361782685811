export default {
    data:{
        readonly: false,
        closeButton: true,
        closeName: 'Voltar'
    },
    struct: [
        [
            {
                col: 0,
                name: "cco_emp_id",
                type: "hidden",
                value: "",
                label: false,
                customClass: 'hidden'
            },
            {
                col: 12,
                name: "cco_cargo",
                type: "select",
                value: "",
                options:['Diretor','T.I', 'Financeiro', 'Marketing'],
                label: "Cargo",
                required: true
            },
            {
                col: 6,
                name: "cco_whatsapp",
                type: "text",
                value: "",
                label: "Whatsapp",
            },
            {
                col: 6,
                name: "cco_fone",
                type: "text",
                value: "",
                label: "Telefone"
            },
            {
                col: 12,
                name: "cco_email",
                type: "text",
                value: "",
                label: "Email"
            },
            {
                col: 6,
                name: "cco_notificar_financeiro",
                type: "checkbox",
                value: false,
                label: "Notificar Financeiro"
            },
            {
                col: 6,
                name: "cco_notificar_chamados",
                type: "checkbox",
                value: false,
                label: "Notificar Chamado"
            },
            {
                col: 12,
                name: "cco_obs",
                type: "textarea",
                value: "",
                label: "Obs"
            }
        ]
    ]
}