export default {
    data:{
        readonly: false,
        closeButton: true,
        closeName: 'Voltar'
    },
    struct: [
        [
            {
                col: 0,
                name: "cse_ser_id",
                type: "hidden",
                value: "",
                label: false,
                customClass: 'hidden'
            },
            {
                col: 0,
                name: "cse_id",
                type: "hidden",
                value: "",
                label: false,
                customClass: 'hidden'
            },
            {
                col: 12,
                name: "ser_nome",
                type: "select",
                value: "",
                options:[],
                label: "Serviço",
                required: true
            },
            {
                col: 6,
                name: "cse_data_inicio",
                type: "date",
                value: "",
                label: "Data Inicio",
                required: true
            },
            {
                col: 6,
                name: "cse_data_fim",
                type: "date",
                value: "",
                label: "Data Fim",
                required: true
            },
            {
                col: 12,
                name: "cse_valor_liquido",
                type: "text",
                value: "",
                label: "Valor Liquido"
            },
            {
                col: 12,
                name: "cse_valor_bruto",
                type: "text",
                value: "",
                label: "Valor Bruto"
            },
            {
                col: 12,
                name: "cse_obs",
                type: "textarea",
                value: "",
                label: "Obs"
            }
        ]
    ]
}