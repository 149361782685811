import {timeMask} from "@/functions/Masks";
export default {
    struct: [
        [
            {
                col: 12,
                name: "tempo_trabalho",
                value: "",
                type: "text",
                label: "Tempo de Trabalho",
                required: true,
                mask: timeMask,
                validateFunc(e:any){
                    if(e.length > 3){
                        const minutes = e.slice(-2)
                        if(minutes > 59){
                            return 'Insira um valor de minutos válido (até 59)'
                        }
                        return true
                    }
                    return 'Tempo de trabalho inválido'
                }
            },
            {
                col: 12,
                name: "motivo_finalizacao",
                value: "",
                type: "textarea",
                label: "Mensagem",
                required: true
            }
        ]
    ]
}