<template>
  <div>
    <div style="text-align: center; width: 100%">
      <va-button class="my-1" gradient @click="adicionarServico()">
        Adicionar
      </va-button>
      <va-modal
        v-model="showModalAdd"
        hide-default-actions="true"
        size="large"
        blur
        fixed-layout
      >
        <template #header>
          <h2>Novo Servico</h2>
        </template>
        <template #default>
          <monta-form v-if="loaded" :form="formServico" @close="showModalAdd = null" @complete="(data) => addNewItem(data)"></monta-form>
        </template>
      </va-modal>
      <va-button class="my-2" color="success" gradient @click="salvarServicos(this)">
        Salvar Servicos
      </va-button>
    </div>
    <va-data-table
      :no-data-html="'Sem servicos cadastrados'"
      :items="table.items"
      :columns="table.columnsSelecteds"
      :animated="true"
      :hoverable="true"
      :striped="true"
      :per-page="table.perPage"
      :current-page="table.currentPage"
      :filter="table.filter"
      @filtered="table.filtered = $event.items"
    >
      <template #cell(ser_nome)="{ source: ser_nome }">
        {{ ser_nome }}
      </template>
      <template #cell(cse_data_inicio)="{ source: cse_data_inicio }">
        {{ cse_data_inicio ? formatDate(cse_data_inicio, 'YYYY-MM-DD','DD/MM/YYYY') : '' }}
      </template>
      <template #cell(cse_data_fim)="{ source: cse_data_fim }">
        {{ cse_data_fim ? formatDate(cse_data_fim, 'YYYY-MM-DD','DD/MM/YYYY') : '' }}
      </template>
      <template #cell(cse_valor_liquido)="{ source: cse_valor_liquido }">
        {{ cse_valor_liquido == 0 ? 'N/A' : cse_valor_liquido }}
      </template>
      <template #cell(cse_valor_bruto)="{ source: cse_valor_bruto }">
        {{ cse_valor_bruto == 0 ? 'N/A' : cse_valor_bruto }}
      </template>
      <template #cell(cwe_actions)="{ source: cwe_actions, rowIndex }">
        <va-button flat icon="edit" @click="openModalToEditItemById(cwe_actions, rowIndex)" />
        <va-button flat icon="delete" color="danger" gradient @click="deleteItemById(rowIndex)" />
      </template>
    </va-data-table>
    <va-modal
      v-model="editedItem"
      message="Editar serviço"
      hide-default-actions="true"
      size="large"
      blur
      fixed-layout
    >
      <monta-form v-if="loaded" :form="formServico" @close="editedItem = null"  @complete="(data) => editItem(data)"></monta-form>
    </va-modal>
    <div class="row justify--center">
      <div class="flex xs2 sm2 md2 lg2 xl2">
        <va-select
          type="number"
          placeholder="Itens"
          label="Itens"
          v-model.number="table.perPage"
          :options="[10, 20, 30, 50, 100, 200, 500]"
        />
      </div>
      <div class="flex">
        <va-pagination
          v-model="table.currentPage"
          input
          :pages="pages"
        />
      </div>
      <div class="flex">
        <va-button class="my-1" @click="showModalPersonalizar = !showModalPersonalizar">
          Personalizar
        </va-button>
      </div>
    </div>
    <va-modal
      v-model="showModalPersonalizar"
      size="large"
      blur
      fixed-layout
      v-on:ok="()=>salvaEstrutura(this)"
    >
      <template #header>
        <h2>Personalizar</h2>
      </template>
      <template #default>
        <div class="row justify--center">
          <div class="flex" :key="col.key" v-for="col in table.columns.map((c) =>{return {key: c.key,label: c.label }})">
            <va-checkbox class="mt-4" v-model="table.columnsSelectedsKeys[col.key]" :label="col.label" />
          </div>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import {
  loadDataFromStorage,
  loadTiposServicos,
  refresh,
  reloadColumns,
  salvaEstrutura,
  salvarServicos,
  loadForms,
  loadServico,
  resetForm,
  assertServicosList
} from './EditarServicosFunctions'
import EditarServicosTable from './EditarServicosTable'
import EditarServicoFormStruct from './EditarServicosFormStruct'
import MontaForm from "@/components/monta-form/MontaForm";
import {formatDate} from "@/functions/Formatters";

export default {
  name: "EditarServicos",
  props:['idCliente'],
  components: { MontaForm },
  async created(){
    await loadTiposServicos(this)
    await loadForms(this)
  },
  async mounted() {
    loadDataFromStorage(this);
    reloadColumns(this)
    await refresh(this)
  },
  data(){
    return{
      table: EditarServicosTable,
      formServico: EditarServicoFormStruct,
      editedItemId: null,
      editedItem: null,
      id: this.idCliente,
      showModalAdd: false,
      showModalPersonalizar: false,
      loaded: false
    }
  },
  computed: {
    pages() {
      return (this.table.perPage && this.table.perPage !== 0)
          ? Math.ceil(this.table.filtered.length / this.table.perPage)
          : this.table.filtered.length
    }
  },
  methods:{
    refresh,
    salvaEstrutura,
    salvarServicos,
    resetForm,
    formatDate,
    adicionarServico(){
      this.resetForm(this)
      this.showModalAdd = !this.showModalAdd
    },
    deleteItemById(id) {
      this.table.items = [
        ...this.table.items.slice(0, id),
        ...this.table.items.slice(id + 1),
      ]
    },
    assertServicosList,
    addNewItem (data) {
      const idServico = this.tiposServicos.find((el)=> el.ser_nome === data.ser_nome )
      data.cse_ser_id = idServico.ser_id
      data.cse_cem_id = this.id

      this.table.items = this.assertServicosList([...this.table.items, { ...data}])
      this.showModalAdd = false
    },
    editItem (data) {
      this.table.items = this.assertServicosList([
        ...this.table.items.slice(0, this.editedItemId),
        { ...data },
        ...this.table.items.slice(this.editedItemId + 1),
      ])
      this.resetEditedItem()
    },
    openModalToEditItemById (id, rowIndex) {
      this.editedItemId = rowIndex
      this.editedItem = { ...this.table.items[rowIndex] }
      loadServico(this, id)
    },
    resetEditedItem () {
      this.editedItem = null
      this.editedItemId = null
    }
  },
  watch: {
    'table.items'(){
      if (window.localStorage) {
        localStorage.setItem('numLines', this.table.perPage.toString())
      }
    }
  }
}
</script>

<style scoped>

</style>