<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg12 xl12">
      <va-card-content>
        <h5 class="va-h5">Filtros:</h5>
        <div class="row justify-start align-center va-spacing-x-1">
          <va-date-input
            class="flex"
            v-model="dataInicio"
            :label="`Encerramento Início`"
          />
          <va-date-input
            class="flex"
            v-model="dataFim"
            :label="`Encerramento Fim`"
          />
          <va-select
            class="flex"
            maxHeight="320px"
            searchable
            v-model="atendente"
            label="Atendente"
            :options="atendentes"
          />
        </div>
        <br>
        <va-divider />
        <br>
        <div v-if="(Object.keys(pontuacaoAtendentes).length > 0)" >
          <h5 class="va-h5">Total de pontos:</h5>
          <div class="row justify-start align-center va-spacing-x-1">
            <va-button
              v-for="(pontos, nome) in pontuacaoAtendentes"
              :key="nome"
              style="cursor: default"
              preset="secondary"
              border-color="primary"
              class="flex"
            >
              {{ nome }}: {{ pontos }}
            </va-button>
          </div>
        </div>
        <br>
        <va-divider />
        <br>
        <div class="row justify-space-between align-center va-spacing-x-1">
          <h5 class="va-h5 flex">Listagem de chamados:</h5>
        </div>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(acoes)="{ source: acoes }">
            <va-button @click="$router.push({name: 'painel-chamados-detalhes', params: { cha_id: acoes } })" icon="visibility" color="#692BEB" gradient class="mr-4" />
            <va-button @click="() => redirectToTomTicket(this, acoes)" icon="outbound" color="#9e4657" gradient class="mr-4" />
          </template>
          <template #cell(cha_protocolo)="{ source: cha_protocolo }">
            #{{ cha_protocolo }}
          </template>
          <template #cell(cst_nome)="{ source: cst_nome }">
            <va-chip :color="getColor(cst_nome)">{{ cst_nome }}</va-chip>
          </template>
          <template #cell(cha_data_criacao)="{ source: cha_data_criacao }">
            {{ cha_data_criacao ? formatDate(cha_data_criacao, 'YYYY-MM-DD HH:ii:ss','DD/MM') : '' }}
          </template>
          <template #cell(cha_data_encerramento)="{ source: cha_data_encerramento }">
            {{ cha_data_encerramento ? formatDate(cha_data_encerramento, 'YYYY-MM-DD HH:ii:ss','DD/MM') : '' }}
          </template>
          <template #cell(prazo_dia)="{ source: prazo_dia }">
            {{ prazo_dia ? formatDate(prazo_dia, 'YYYY-MM-DD','DD/MM') : '' }}
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {
  refresh,
  setAtendentes,
  getColor,
  redirectToTomTicket
} from "./ChamadosFunctions";
import ChamadosTable from "./ChamadosTable";
import { formatDate } from "@/functions/Formatters"

export default {
  async mounted() {
    await setAtendentes(this)
  },
  name: "Chamados",
  data() {
    const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    return {
      table: ChamadosTable,
      pontuacaoAtendentes: {},
      atendente: 'Todos',
      apenaspendentes: true,
      atendentes: ['Todos'],
      dataFim: new Date(y, m + 1, 0),
      dataInicio: new Date(y, m, 1)
    }
  },
  methods:{
    refresh,
    getColor,
    formatDate,
    redirectToTomTicket
  },
  watch: {
    'dataFim'() {
      this.refresh(this)
    },
    'dataInicio'() {
      this.refresh(this)
    },
    'atendente'() {
      this.refresh(this)
    }
  }
}
</script>

<style>
.table-pontos .va-data-table__table-td {
  max-width: 150px;
  white-space: normal;
}
</style>