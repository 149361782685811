<template>
  <div class="row justify--center">
    <div class="flex xs12 sm12 md12 lg12 xl12">
      <va-card  class="flex mb-4">
        <va-card-title>
        </va-card-title>
        <va-card-content>
          <va-accordion
            v-model="dropdowns"
            multiply
          >
            <va-collapse 
              header="Dados do cliente"
            >
              <EditarDados :idCliente=this.$route.params.id></EditarDados>
            </va-collapse>
            <va-collapse
              header="Sites do cliente"
            >
              <EditarSite :idCliente=this.$route.params.id></EditarSite>
            </va-collapse>
            <va-collapse
              header="Serviços do cliente"
            >
              <EditarServicos :idCliente=this.$route.params.id></EditarServicos>
            </va-collapse>
            <va-collapse
              header="Contatos do cliente"
            >
              <EditarContatos :idCliente=this.$route.params.id></EditarContatos>
            </va-collapse>
          </va-accordion>
        </va-card-content>
      </va-card>
    </div>
  </div>
</template>

<script>
import EditarSite from './editar-sites/EditarSites'
import EditarDados from './editar-dados/EditarDados'
import EditarServicos from './editar-servicos/EditarServicos'
import EditarContatos from './editar-contatos/EditarContatos'

export default {
  name: "EditarCliente",
  components: {EditarSite, EditarDados, EditarServicos, EditarContatos},
  data() {
    return {
      idClient: this.$route.params.id,
      dropdowns: [false, false, false, false],
    }
  }
}
</script>

<style>
</style>