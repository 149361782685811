import {FetchWs} from "@/functions/FetchWs";
import {EditarContatosInterfaces} from "@/pages/painel/clientes/editar/editar-contatos/EditarContatosInterfaces";
import {delFormValues, setFormValues} from "@/components/monta-form/functions";

export function reloadColumns(vue: any){
    vue.table.columnsSelecteds = vue.table.columns.filter((col:any)=>{
        return Object.keys(vue.table.columnsSelectedsKeys).includes(col.key) && vue.table.columnsSelectedsKeys[col.key]
    })
}

export function loadDataFromStorage(vue: any){
    if (window.localStorage && localStorage.getItem('numLines')) {
        vue.table.perPage = localStorage.getItem('numLines')
    }
    if (window.localStorage && localStorage.getItem('columnsSelectedsKeys')) {
        vue.table.columnsSelectedsKeys = JSON.parse(localStorage.getItem('columnsSelectedsKeys') ?? '')
    }
}

export async function refresh(vue: any)
{
    vue.table.items = await getContatosList(vue)
    vue.table.filtered = vue.table.items
}

export async function getContatosList(vue: any): Promise<any>
{
    vue.items = [];
    const response = await FetchWs('cliente/get-contacts-list/' + vue.id);
    const data = response?.data;

    if(data && data.length > 0){
        return assertContactsList(data);
    } else {
        return data;
    }
}

export function assertContactsList(objs: EditarContatosInterfaces[]): EditarContatosInterfaces[]
{
    objs.forEach((value:EditarContatosInterfaces, key:number) => {
        objs[key].cco_id = value.cco_id
        objs[key].tmp_key = Math.floor(Math.random() * 10000000);
        objs[key].cco_actions = objs[key].tmp_key
        objs[key].cco_cargo = value.cco_cargo
        objs[key].cco_whatsapp = value.cco_whatsapp
        objs[key].cco_fone = value.cco_fone
        objs[key].cco_email = value.cco_email
        objs[key].cco_notificar_financeiro = value.cco_notificar_financeiro
        objs[key].cco_notificar_chamados = value.cco_notificar_chamados
        objs[key].cco_obs = value.cco_obs
    })
    return objs;
}

export async function salvarContatos( vue: any )
{
    const idCliente = vue.id
    const contatos = JSON.stringify(vue.table.items)

    const body = new FormData();
    body.append("idCliente", idCliente);
    body.append("contatos", contatos);
    const response = await FetchWs('cliente/update-contatos/' + idCliente, 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}

export function salvaEstrutura(vue: any){
    const columnsSelectedsKeys = {...vue.table.columnsSelectedsKeys}
    if (window.localStorage) {
        localStorage.setItem('columnsSelectedsKeys', JSON.stringify(columnsSelectedsKeys))
    }
    reloadColumns(vue)
}

export function resetForm(vue:any){
    delFormValues(vue, 'formContato')
}

export async function loadContato(vue: any, idContato: any) {
    const values = vue.table.items.find((cat:any)=>cat.tmp_key === idContato)
    if(values.cco_notificar_chamados === 1) {values.cco_notificar_chamados = true}
    if(values.cco_notificar_chamados === 0) {values.cco_notificar_chamados = false}
    if(values.cco_notificar_financeiro === 1) {values.cco_notificar_financeiro = true} 
    if(values.cco_notificar_financeiro === 0) {values.cco_notificar_financeiro = false}
    setFormValues(vue, 'formContato', values)
}