<template>
  <div style="margin-left: 0.5%;">
    <VaButtonGroup
      size="small"
      gradient
      color="info"
      icon-color="#FFFFFF"
    >
      <VaPopover placement="right" message="Limite emails">
        <VaButton icon="mail" v-if="this.limite_emails != 0">
          {{ this.limite_emails }}
        </VaButton>
      </VaPopover>
      <VaPopover placement="right" message="Limite chamados">
        <VaButton icon="support_agent" v-if="this.limite_chamados != 0">
          {{ this.limite_chamados }}
        </VaButton>
      </VaPopover>
    </VaButtonGroup>
  </div>

  <div class="row justify-center align-center va-spacing-x-1 chamados--detalhes">
    <va-card class="flex xs12 sm12 md12 lg12 xl12">
      <va-card-content>
        <div class="row justify-center align-center va-spacing-x-1">
          <va-button class="flex" v-if="btnExcluir" @click="showExcluirModal = true" color="danger"> Excluir Chamado
          </va-button>
          <va-button class="flex" @click="showPrazoModal = true"> Alterar Prazo</va-button>
          <div v-if="revisao">
            <va-button color="rgb(146,220,231)" class="flex" @click="showRevisaoModal = true"> Revisão</va-button>
          </div>
          <div v-if="!revisao">
            <va-button color="rgb(195, 195, 195)" class="flex" @click="showDevolverModal = true"> Devolver</va-button>
          </div>
          <div v-else></div>
          <va-button class="flex" @click="showFinalizarModal = true" color="success"> Finalizar</va-button>
        </div>
        <br>
        <va-accordion
          v-model="dropdowns"
          multiply
        >
          <va-alert v-if="this.limite_chamados != 0 && (this.qtd_chamados > this.limite_chamados  && this.verifyCompany)"
            closeable
            title="Atenção"
            color="warning"
            icon="warning"
            class="mb-6 mt-6"
          >
            Este cliente excedeu o limite de {{ this.limite_chamados }} com {{ this.qtd_chamados }} chamados este mês.
            Verifique com o Edson sobre o atendimento deste chamado.
          </va-alert>
          <va-collapse
            header="Dados do cliente"
            color="primary"
          >
            <va-card color="#f7fafc">
              <va-card-content>
                <monta-form v-if="loaded" :form="formCliente"
                  @complete="(data) => sendFormCliente(this, data)"></monta-form>
              </va-card-content>
            </va-card>
          </va-collapse>
          <br>
          <va-collapse
            header="Detalhes do chamado"
            color="primary"
          >
            <va-card color="#f7fafc">
              <va-card-content>
                <monta-form v-if="loaded" :form="formChamado"
                  @complete="(data) => sendFormChamado(this, data)"></monta-form>
              </va-card-content>
            </va-card>
          </va-collapse>
        </va-accordion>
      </va-card-content>
    </va-card>

    <!--//Modals-->
    <!--prazo-->
    <va-modal
      v-model="showPrazoModal"
      size="large"
      hide-default-actions
    >
      <template #header>
        <div class="row justify-center align-center">
          <h5 class="flex va-h5">Alterar Prazo</h5>
        </div>
        <br>
      </template>
      <template #default>
        <div class="row">
          <monta-form v-if="loaded" :form="formPrazo" @complete="(data) => updatePrazo(this, data)"></monta-form>
        </div>
      </template>
    </va-modal>
    <!--finalizar-->
    <va-modal
      v-model="showFinalizarModal"
      size="large"
      :mobile-fullscreen="false"
      :fixed-layout="true"
      hide-default-actions
    >
      <template #header>
        <div class="row justify-center align-center">
          <h5 class="flex va-h5">Finalizar Chamado</h5>
        </div>
        <br>
      </template>
      <template #default>
        <div class="row">
          <monta-form v-if="loaded" :form="formFinalizar"
            @complete="(data) => finalizarChamado(this, data)"></monta-form>
        </div>
      </template>
    </va-modal>
    <!--excluir-->
    <va-modal
      v-model="showExcluirModal"
      size="large"
      hide-default-actions
    >
      <template #header>
        <div class="row justify-center align-center">
          <h5 class="flex va-h5">Excluir Chamado</h5>
        </div>
        <br>
      </template>
      <template #default>
      </template>
      <template #footer>
        <va-button class="mx-1" color="gray" @click="showExcluirModal = false">
          Voltar
        </va-button>
        <va-button class="mx-1" color="danger" @click="excluirChamado(this)">
          Excluir
        </va-button>
      </template>
    </va-modal>
    <!--revisao-->
    <va-modal
      v-model="showRevisaoModal"
      size="large"
      hide-default-actions
    >
      <template #header>
        <div class="row justify-center align-center">
          <h5 class="flex va-h5">Encaminhar para Revisão</h5>
        </div>
        <br>
      </template>
      <template #default>
      </template>
      <template #footer>
        <va-button class="mx-1" color="gray" @click="showRevisaoModal = false">
          Voltar
        </va-button>
        <va-button class="mx-1" color="success" @click="alterarParaRevisao(this)">
          Confirmar
        </va-button>
      </template>
    </va-modal>
    <!--devolver-->
    <va-modal
      v-model="showDevolverModal"
      size="large"
      hide-default-actions
    >
      <template #header>
        <div class="row justify-center align-center">
          <h5 class="flex va-h5">Devolver para Fila</h5>
        </div>
        <br>
      </template>
      <template #default>
      </template>
      <template #footer>
        <va-button class="mx-1" color="gray" @click="showDevolverModal = false">
          Voltar
        </va-button>
        <va-button class="mx-1" color="success" @click="alterarParaDevolver(this)">
          Confirmar
        </va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import MontaForm from "@/components/monta-form/MontaForm";
import ClienteFormStruct from "./ClienteFormStruct";
import ChamadoFormStruct from "./ChamadoFormStruct";
import AlteraPrazoFormStruct from "./AlteraPrazoFormStruct";
import {
  loadAtendentes,
  loadCategorias,
  loadChamadoAndCliente,
  loadForms,
  sendFormChamado,
  sendFormCliente,
  updatePrazo,
  finalizarChamado,
  excluirChamado,
  chamadoExcluido,
  alterarParaRevisao,
  alterarParaDevolver,
  getQtdChamados,
  loadNomeFantasia,
  verifyCompany
} from "./DetalhesFunctions";
import FinalizarFormStruct from "./FinalizarFormStruct";

export default {
  name: "Detalhes",
  components: {MontaForm},
  created: async function () {
    await Promise.all([
      loadChamadoAndCliente(this),
      loadAtendentes(this),
      loadCategorias(this),
      loadNomeFantasia(this),
      chamadoExcluido(this, this.$route.params.cha_id)
    ])
    loadForms(this);
    await getQtdChamados(this);
    await verifyCompany(this)
  },
  data() {
    return {
      chamado: false,
      atendentes: [],
      categorias: [],
      nomeFantasia: [],
      formCliente: ClienteFormStruct,
      formChamado: ChamadoFormStruct,
      formPrazo: AlteraPrazoFormStruct,
      formFinalizar: FinalizarFormStruct,
      dropdowns: [true, true, false],
      loaded: false,
      showPrazoModal: false,
      showFinalizarModal: false,
      showExcluirModal: false,
      showRevisaoModal: false,
      showDevolverModal: false,
      novoPrazo: '',
      motivo_finalizacao: '',
      btnExcluir: false,
      revisao: false,
      qtd_chamados: 0,
      limite_chamados: 0,
      limite_emails: 0,
      verifyCompany: false
    }
  },
  methods: {
    sendFormCliente,
    sendFormChamado,
    updatePrazo,
    finalizarChamado,
    excluirChamado,
    alterarParaRevisao,
    alterarParaDevolver
  }
}
</script>
<style lang="scss">
.chamados--detalhes {
  .va-collapse__body-wrapper {
    overflow: visible;
  }

  .input__readonly {
    .va-input-wrapper__field::after {
      background: #eaeaea;
    }
  }

  .va-input-wrapper__field::after {
    background: #fff;
  }

  .hidden {
    display: none;
  }
}
</style>