export default {
    struct: [
        [
            {
                col: 0,
                name: "cha_cli_id",
                value: "",
                type: "hidden",
                placeholder: false,
                label: false,
                required: false,
                customClass: 'hidden'
            },
            {
                col: 3,
                name: "cli_nome",
                value: "",
                type: "text",
                placeholder: "Ex.: João das Neves",
                label: "Nome",
                required: true
            },
            {
                col: 3,
                name: "cli_email",
                value: "",
                type: "email",
                placeholder: "Ex.: joao@stark.com",
                label: "E-mail",
                required: true
            },
            {
                col: 3,
                name: "cli_whatsapp",
                value: "",
                type: "text",
                placeholder: "Ex: 554195834689",
                label: "WhatsApp",
                required: true
            },
            {
                col: 3,
                name: "cem_nome_fantasia",
                type: "select",
                value: "",
                options: [],
                label: "Empresa do Cliente",
                searchable: true,
                required: true,
            },
        ]
    ]
}