import {FetchWs} from "@/functions/FetchWs";
import {EditarServicosInterfaces} from "@/pages/painel/clientes/editar/editar-servicos/EditarServicosInterfaces";
import {delFormValues,  setFormValues, setOptionsForm} from "@/components/monta-form/functions";

export function reloadColumns(vue: any){
    vue.table.columnsSelecteds = vue.table.columns.filter((col:any)=>{
        return Object.keys(vue.table.columnsSelectedsKeys).includes(col.key) && vue.table.columnsSelectedsKeys[col.key]
    })
}

export function loadDataFromStorage(vue: any){
    if (window.localStorage && localStorage.getItem('numLines')) {
        vue.table.perPage = localStorage.getItem('numLines')
    }
    if (window.localStorage && localStorage.getItem('columnsSelectedsKeys')) {
        vue.table.columnsSelectedsKeys = JSON.parse(localStorage.getItem('columnsSelectedsKeys') ?? '')
    }
}

export async function refresh(vue: any)
{
    vue.table.items = await getServicosList(vue)
    vue.table.filtered = vue.table.items
}

export async function getServicosList(vue: any): Promise<any>
{
    vue.table.items = [];
    const response = await FetchWs('cliente/get-services-list/' + vue.id);
    const data = response?.data;
    vue.table.items = data;
    if(data && data.length > 0){
        return assertServicosList(data);
    } else {
        return data;
    }
}

export function assertServicosList(objs: EditarServicosInterfaces[]): EditarServicosInterfaces[]
{
    objs.forEach((value:EditarServicosInterfaces, key:number) => {
        objs[key].cse_id = value.cse_id
        objs[key].tmp_key = Math.floor(Math.random() * 10000000);
        objs[key].cwe_actions = objs[key].tmp_key
        objs[key].cse_ser_id = value.cse_ser_id
        objs[key].cse_cem_id = value.cse_cem_id
        objs[key].cse_data_inicio = value.cse_data_inicio
        objs[key].cse_data_fim = value.cse_data_fim
        objs[key].cse_valor_bruto = value.cse_valor_bruto
        objs[key].cse_valor_liquido = value.cse_valor_liquido
        objs[key].cse_obs = value.cse_obs
        objs[key].ser_nome = value.ser_nome
    })
    return objs;
}

export async function salvarServicos( vue: any )
{
    const idCliente = vue.id
    const servicos = JSON.stringify(vue.table.items)

    const body = new FormData();
    body.append("idCliente", idCliente);
    body.append("servicos", servicos);
    const response = await FetchWs('cliente/update-servicos/' + idCliente, 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}

export function salvaEstrutura(vue: any){
    const columnsSelectedsKeys = {...vue.table.columnsSelectedsKeys}
    if (window.localStorage) {
        localStorage.setItem('columnsSelectedsKeys', JSON.stringify(columnsSelectedsKeys))
    }
    reloadColumns(vue)
}

export async function loadTiposServicos(vue: any) {
    const response = await FetchWs('servicos/get-all-servicos');
    const data = response?.data;

    if(data && Array.isArray(data) && data.length > 0){
        vue.tiposServicos = data
        vue.tiposServicosNomes = data.map((servico) => servico.ser_nome)
    }
}

export async function loadServico(vue: any, idServico: any) {
    const values = vue.table.items.find((cat:any)=>cat.tmp_key === idServico)
    setFormValues(vue, 'formServico', values)
}

export function resetForm(vue:any){
    delFormValues(vue, 'formServico')
}

export function loadForms(vue: any){
    setOptionsForm(vue.formServico, 'ser_nome', vue.tiposServicosNomes)
    vue.loaded = true
}