<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader)"
        >
          <template #cell(usu_name)="{ source: usu_name }">
            {{ usu_name }}
          </template>
          <template #cell(usu_email)="{ source: usu_email }">
            {{ usu_email }}
          </template><template #cell(usu_data_cad)="{ source: usu_data_cad }">
            {{ formatDate(usu_data_cad, 'YYYY-MM-DD','L') }}
          </template>
          <template #cell(edit_id)="{ source: edit_id }">
            <va-button icon="visibility" class="" @click="acoesModal = edit_id"/>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
    <va-modal v-model="acoesModal">
      <div>
        <va-button class="btn-dropdown" icon-right="visibility" color="success" gradient @click="acessarUser(acoesModal, this)" size="medium">Acessar Usuário</va-button><br>
        <va-button class="btn-dropdown" icon-right="edit" color="info" gradient @click="editUser(acoesModal, this)" size="medium">Editar Usuário</va-button><br>
        <va-button class="btn-dropdown" icon-right="edit" color="#800080" gradient @click="editPerms(acoesModal, this)" size="medium">Permissões</va-button><br>
        <va-button class="btn-dropdown" icon-right="lock_open" color="warning" gradient @click="changePass(acoesModal, this)" size="medium">Trocar Senha</va-button><br>
        <va-button class="btn-dropdown" icon-right="delete" color="danger" gradient @click="deleteModal = lastDeleteModalID = acoesModal;acoesModal = false;" size="medium">Excluir Usuário</va-button>
      </div>
    </va-modal>
    <va-modal v-model="deleteModal" message="Deseja realmente excluir este usuário?" ok-text="Excluir" @ok="deleteUser(lastDeleteModalID, this)" />
  </div>
</template>

<script>
import UsersTable from "@/pages/painel/usuarios/listagem/UsuariosTable";
import {refresh, editUser, deleteUser, changePass, acessarUser, editPerms} from "./UsuariosFunctions"
import {formatDate} from "@/functions/Formatters"

export default {
  name: "usuarios",
  async mounted() {
    await refresh(this);
  },
  data() {
    return {
      table: UsersTable,
      deleteModal: false,
      acoesModal: false,
      lastDeleteModalID: false,
    }
  },
  methods:{
    formatDate,
    editPerms,
    editUser,
    acessarUser,
    deleteUser,
    changePass,
    refresh
  },
}
</script>

<style scoped>
.btn-dropdown{
  min-width: 165px;
  margin-bottom: 3px;
}
</style>