import {UsuariosInterfaces} from "@/pages/painel/usuarios/listagem/UsuariosInterfaces";
import {FetchWs} from "@/functions/FetchWs";


export async function refresh(vue: any): Promise<void>
{
    vue.table.items = [];
    const response = await FetchWs('user/get-all');
    if(response && response.success && response.data.length > 0){
        vue.table.items = assertList(response.data);
    }
}

function assertList(objs: UsuariosInterfaces[]): UsuariosInterfaces[]
{
    objs.forEach((value:UsuariosInterfaces,key:number) => {
        objs[key].edit_id = value.usu_id
        objs[key].usu_name = value.usu_name
        objs[key].usu_email = value.usu_email
        objs[key].usu_data_cad = value.usu_data_cad
    })
    return objs;
}

export async function editUser(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'editar-usuario', params:{id: id}});
}

export async function editPerms(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'editar-permissoes', params:{id: id}});
}

export async function acessarUser(id: number, vue: any): Promise<void>
{
    vue.acoesModal = false;
    const response = await FetchWs('auth/acessar-user/' + id);
    if(response?.success && response?.data?.jwt) {
        if( !localStorage.getItem('JWT_TOKEN_OLD')){
            localStorage.setItem('JWT_TOKEN_OLD', localStorage.getItem('JWT_TOKEN') ?? '');
        }
        localStorage.setItem('JWT_TOKEN', response.data.jwt)
        vue.$router.push({path:'/'})
        setTimeout(()=>location.reload(),500)
    }
}

export async function deleteUser(id: number, vue:any): Promise<void>
{
    await FetchWs('user/delete/' + id, 'GET');
    refresh(vue)
}

export async function changePass(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'mudar-senha', params:{id: id}});
}
