import {FetchWs} from "@/functions/FetchWs";
import {EditarSitesInterfaces} from "@/pages/painel/clientes/editar/editar-sites/EditarSitesInterfaces";


export async function getSiteList(vue: any): Promise<any>
{
    vue.items = [];
    const response = await FetchWs('cliente/get-site-list/' + vue.id);
    if(response && response.success){
        vue.cwe_url = response.data.cwe_url
        vue.items = assertSiteList(response.data);
    } else {
        return false;
    }
}

function assertSiteList(objs: EditarSitesInterfaces[]): EditarSitesInterfaces[]
{
    objs.forEach((value:EditarSitesInterfaces, key:number) => {
        objs[key].cwe_url = value.cwe_url
        objs[key].cwe_obs = value.cwe_obs
        objs[key].cwe_id = value.cwe_id
        objs[key].cwe_actions = value.cwe_url
    })
    return objs;
}

export async function salvarSites( vue: any )
{
    const idCliente = vue.id
    const sites = JSON.stringify(vue.items)

    const body = new FormData();
    body.append("idCliente", idCliente);
    body.append("sites", sites);
    const response = await FetchWs('cliente/update-sites/' + idCliente, 'POST',body);
    if(response?.success) {
        window.history.back();
    }
}